/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sn-twitch': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.451.71C13.256.243 11.11.02 9 0 6.89.019 4.744.243 3.568.692 1.386 1.539.667 3.752.518 4.303c0 .003.002-.003-.007.03a8.063 8.063 0 00-.053.224 18.961 18.961 0 00-.456 4.434c-.018 1.366.085 2.888.442 4.4a20.084 20.084 0 00.075.305l.014.052c.177.627.916 2.737 3.035 3.56 1.176.449 3.323.673 5.432.692 2.11-.019 4.256-.224 5.432-.692 2.181-.847 2.9-3.059 3.05-3.611 0-.003-.002.003.006-.03l.022-.085.032-.14c.35-1.528.474-3.069.456-4.433.018-1.378-.087-2.914-.434-4.42l-.035-.152a7.122 7.122 0 00-.023-.091l-.009-.035c-.155-.571-.88-2.759-3.046-3.6z" _fill="#898B9B"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M5.147 13.063L6 14.462a.26.26 0 00.224.125h1c.09 0 .174-.046.222-.123l.87-1.402a.262.262 0 01.222-.124h3.043c.069 0 .135-.028.184-.077l3.158-3.157A.261.261 0 0015 9.519V3.262A.262.262 0 0014.738 3H4.394a.262.262 0 00-.224.127L3.037 5.015A.26.26 0 003 5.149v7.527a.26.26 0 00.261.262h1.663a.26.26 0 01.223.125zm.064-1.775h.73a.15.15 0 01.13.075l.531.93c.058.101.202.101.26 0l.531-.93a.15.15 0 01.13-.075h3.692a.15.15 0 00.105-.043l2.399-2.4a.15.15 0 00.044-.105V5.21a.15.15 0 00-.15-.148H5.211a.15.15 0 00-.15.149v5.928c0 .082.068.15.15.15z" _fill="#fff"/><path pid="2" d="M7.124 9.095V6.842c0-.072.059-.131.13-.131h1.017c.072 0 .131.059.131.131v2.253a.131.131 0 01-.131.131H7.255a.131.131 0 01-.131-.13zM10.01 9.095V6.842c0-.072.059-.131.131-.131h1.016c.073 0 .131.059.131.131v2.253a.131.131 0 01-.13.131H10.14a.131.131 0 01-.131-.13z" _fill="#fff"/>'
  }
})
